var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改中国海关编码",
        visible: _vm.dialogVisible,
        width: "500px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "top",
            "label-width": "100px",
            model: _vm.form,
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "新海关编码", required: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入海关编码" },
                model: {
                  value: _vm.form.hsCodeCn,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "hsCodeCn", $$v)
                  },
                  expression: "form.hsCodeCn"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }