<template>
  <el-dialog
    title="修改中国海关编码"
    :visible.sync="dialogVisible"
    width="500px"
    @close="handleClose"
  >
    <el-form
      ref="form"
      label-position="top"
      label-width="100px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="新海关编码" required>
        <el-input v-model="form.hsCodeCn" placeholder="请输入海关编码" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'UpdateHsCodeDialog',
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      defaultHsCode: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        form: {
          hsCodeCn: '',
        },
        rules: {
          hsCodeCn: [
            { required: true, message: '请输入新海关编码', trigger: 'blur' },
          ],
        },
      }
    },
    computed: {
      dialogVisible: {
        get() {
          return this.visible
        },
        set(val) {
          this.$emit('update:visible', val)
        },
      },
    },
    watch: {
      visible(val) {
        if (val) {
          this.form.hsCodeCn = ''
        }
      },
    },
    methods: {
      handleClose() {
        this.dialogVisible = false
        this.form.hsCodeCn = ''
      },
      handleConfirm() {
        if (!this.form.hsCodeCn) {
          this.$message.warning('请输入海关编码')
          return
        }
        this.$emit('confirm', this.form.hsCodeCn)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-form-item {
    margin-bottom: 0;
  }
</style>
