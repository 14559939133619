<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          label-width="140px"
          :model="queryForm"
          @submit.native.prevent
        >
          <div class="cont-form">
            <div>
              <el-form-item label="" label-width="60px" prop="jobNo">
                <el-input
                  v-model="queryForm.jobNo"
                  placeholder="运单号"
                  :rows="3"
                  style="width: 200px"
                  type="textarea"
                />
              </el-form-item>
            </div>
            <div>
              <el-form-item class="my-form-item" label="柜号" prop="cabinetNo">
                <el-input
                  v-model="queryForm.cabinetNo"
                  clearable
                  placeholder="请输入"
                  style="width: 180px"
                />
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="子单号"
                prop="childJobNo"
              >
                <el-input
                  v-model="queryForm.childJobNo"
                  clearable
                  placeholder="请输入"
                  style="width: 180px"
                />
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="是否混装"
                prop="isMixed"
              >
                <el-select
                  v-model="queryForm.isMixed"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in yseOrNo"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="报关方式" prop="customsType">
                <el-select
                  v-model="queryForm.customsType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in customsType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="清关方式"
                prop="taxType"
              >
                <el-select
                  v-model="queryForm.taxType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in taxType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="客户中文品名"
                prop="cusProductCnName"
              >
                <el-input
                  v-model="queryForm.cusProductCnName"
                  clearable
                  placeholder="请输入"
                  style="width: 180px"
                />
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="中文材质"
                prop="materialCn"
              >
                <el-input
                  v-model="queryForm.materialCn"
                  clearable
                  placeholder="请输入"
                  style="width: 180px"
                />
              </el-form-item>
              <!-- <el-form-item
                class="my-form-item"
                label="报关资料是否完整"
                prop="isCompletion"
              >
                <el-select
                  v-model="queryForm.isCompletion"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in yseOrNo"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item> -->
            </div>
          </div>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button native-type="submit" @click="reset('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel>
        <div class="tools">
          <div>
            <!-- <el-button
          v-show="queryForm.status == 1"
          :disabled="!queryForm.idList.length > 0"
          size="mini"
          type="primary"
          @click="updateData"
        >
          更新
        </el-button> -->
            <el-button
              v-show="queryForm.status == 1"
              :disabled="!queryForm.idList.length > 0"
              size="mini"
              type="primary"
              @click="confirm"
            >
              确认
            </el-button>
            <el-button
              v-show="queryForm.status == 2"
              :disabled="!queryForm.idList.length > 0"
              size="mini"
              type="danger"
              @click="cancel"
            >
              取消确认
            </el-button>
            <el-button
              v-show="queryForm.status == 1"
              :disabled="!queryForm.idList.length > 0"
              size="mini"
              type="danger"
              @click="deleteData"
            >
              删除
            </el-button>
            <el-button
              v-show="queryForm.status == 0 || queryForm.status == 1"
              :disabled="!queryForm.idList.length > 0"
              size="mini"
              type="primary"
              @click="createFile"
            >
              创建档案
            </el-button>
            <el-button
              v-show="queryForm.status == 2"
              :loading="loadingExPort"
              size="mini"
              type="primary"
              @click="exPort"
            >
              导出
            </el-button>
            <!-- <uploadExcel
          v-show="queryForm.status == 0"
          down-url="报关列表导入模版.xlsx"
          :upload-aciton="bt"
        /> -->
            <el-button
              v-show="queryForm.status == 2"
              :disabled="!queryForm.idList.length > 0"
              size="mini"
              type="primary"
              @click="updateD"
            >
              更新底单
            </el-button>
            <el-button size="mini" type="primary" @click="tmsDataSyncOpen">
              手动运单同步
            </el-button>
            <el-button
              v-show="queryForm.status == 0"
              :loading="loadingExPort2"
              size="mini"
              type="primary"
              @click="bottomOrderExport"
            >
              底单导出
            </el-button>
            <el-button
              v-show="queryForm.status == 0"
              :loading="loadingExPort"
              size="mini"
              type="primary"
              @click="exportOfCustomsDeclarationDocuments"
            >
              报关资料导出
            </el-button>
            <el-button
              v-show="queryForm.status == 0 || queryForm.status == 1"
              :disabled="!queryForm.idList.length > 0"
              :loading="loadingHsData"
              size="mini"
              type="primary"
              @click="mathHsData"
            >
              查询海关编码网站
            </el-button>
            <el-button
              :disabled="!queryForm.idList.length > 0"
              :loading="loadingHsData"
              size="mini"
              type="primary"
              @click="batchView"
            >
              批量查看报关推荐
            </el-button>
            <el-button
              v-show="queryForm.status == 0 || queryForm.status == 1"
              :disabled="!queryForm.idList.length > 0"
              :loading="loadingHsData"
              size="mini"
              type="primary"
              @click="updateHsCode"
            >
              修改中国海关编码
            </el-button>
            <el-button
              :disabled="!queryForm.idList.length > 0"
              size="mini"
              type="danger"
              @click="highRisk"
            >
              转高风险资料
            </el-button>
            <el-button
              :disabled="!queryForm.idList.length > 0"
              :loading="loadingLinking"
              size="mini"
              type="primary"
              @click="picturesLinking"
            >
              获取图片链接
            </el-button>
            <el-button size="mini" type="primary" @click="openColumnSettings">
              设置列
            </el-button>
          </div>
          <div>网站接口调用次数： {{ useTimes }}/5500</div>
        </div>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-tabs
      v-model="queryForm.status"
      style="width: 100%"
      type="card"
      @tab-click="queryList"
    >
      <el-tab-pane
        v-for="item in statusList"
        :key="item.id"
        :label="item.name"
        :name="item.id"
        style="height: 25px; line-height: 25px"
      />
    </el-tabs>
    <div style="height: calc(100vh - 470px)">
      <vxe-table
        id="clearanceListTable"
        ref="tableRef"
        v-loading="listLoading"
        border
        :checkbox-config="{ trigger: 'row', range: true }"
        :custom-config="customConfig"
        :data="tableData"
        height="100%"
        :row-class-name="rowClassName"
        :row-config="{ isHover: true, isCurrent: true, height: 35 }"
        :scroll-y="{ enabled: true }"
        show-header-overflow
        show-overflow
        @checkbox-all="selectAllEvent"
        @checkbox-change="selectAllEvent"
      >
        <vxe-column
          align="center"
          field="selectOption"
          fixed="left"
          type="checkbox"
          width="55"
        />
        <vxe-column
          align="center"
          fixed="left"
          title="序号"
          type="seq"
          width="70"
        >
          <template #default="{ seq }">
            {{ seq + (queryForm.pageNum - 1) * queryForm.pageSize }}
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          field="cabinetNo"
          min-width="140"
          title="柜号"
        />
        <vxe-column
          align="center"
          field="jobNo"
          min-width="140"
          title="运单号"
        />
        <vxe-column
          align="center"
          field="customerCode"
          min-width="140"
          title="客户"
        />
        <vxe-column
          align="center"
          field="cusProductCnName"
          min-width="140"
          title="客户中文品名"
        />
        <vxe-column
          align="center"
          field="cusProductEnName"
          min-width="140"
          title="客户英文品名"
        />
        <vxe-column
          align="center"
          field="hsCode"
          min-width="120"
          title="海关编码"
        />
        <vxe-column
          align="center"
          field="reportCnName"
          min-width="140"
          title="申报中文品名"
        />
        <vxe-column
          align="center"
          field="reportEnName"
          min-width="140"
          title="申报英文品名"
        />
        <vxe-column
          align="center"
          field="hsCodeCn"
          min-width="120"
          title="中国海关编码"
        />
        <vxe-column
          align="center"
          field="reportFactor"
          min-width="120"
          title="中国申报要素"
        />
        <vxe-column
          align="center"
          field="materialCn"
          min-width="140"
          title="中文材质"
        />
        <vxe-column
          align="center"
          field="materialEn"
          min-width="140"
          title="英文材质"
        />
        <vxe-column
          align="center"
          field="childJobNo"
          min-width="240"
          show-overflow-tooltip
          title="子单号"
        />
        <vxe-column align="center" field="brand" min-width="140" title="品牌" />
        <vxe-column
          align="center"
          field="brandType"
          min-width="140"
          title="品牌类型"
        />
        <vxe-column align="center" field="specs" min-width="140" title="规格" />
        <vxe-column align="center" field="uses" min-width="140" title="用途" />
        <vxe-column
          align="center"
          field="goodsType"
          min-width="120"
          title="货物类型"
        />
        <vxe-column align="center" field="unit" min-width="80" title="单位" />
        <vxe-column
          align="center"
          field="pcs"
          min-width="80"
          show-overflow-tooltip
          title="件数"
        />
        <vxe-column
          align="center"
          field="splitPcs"
          min-width="80"
          show-overflow-tooltip
          title="拆分件数"
        />
        <vxe-column
          align="center"
          field="netWeight"
          min-width="100"
          title="净重"
        />
        <vxe-column
          align="center"
          field="splitNetWeight"
          min-width="100"
          title="拆分净重"
        />
        <vxe-column
          align="center"
          field="receiveNetWeight"
          min-width="100"
          title="收货总净重"
        />
        <vxe-column
          align="center"
          field="splitReceiveNetWeight"
          min-width="120"
          title="拆分收货总净重"
        />
        <vxe-column align="center" field="qty" min-width="80" title="数量" />
        <vxe-column
          align="center"
          field="vol"
          min-width="80"
          show-overflow-tooltip
          title="体积"
        />
        <vxe-column
          align="center"
          field="splitVol"
          min-width="100"
          show-overflow-tooltip
          title="拆分体积"
        />
        <vxe-column
          align="center"
          field="grossWeight"
          min-width="80"
          title="毛重"
        />
        <vxe-column
          align="center"
          field="splitGrossWeight"
          min-width="100"
          title="拆分毛重"
        />
        <vxe-column
          align="center"
          field="receiveGrossWeight"
          min-width="100"
          title="收货总实重"
        />
        <vxe-column
          align="center"
          field="spliTreceiveGrossWeight"
          min-width="120"
          title="拆分收货总实重"
        />
        <vxe-column align="center" field="price" min-width="80" title="单价" />
        <vxe-column
          align="center"
          field="awsPrice"
          min-width="100"
          title="亚马逊单价"
        />
        <vxe-column
          align="center"
          field="totalPrice"
          min-width="80"
          title="总价"
        />
        <vxe-column
          align="center"
          field="length"
          min-width="80"
          show-overflow-tooltip
          title="长"
        />
        <vxe-column
          align="center"
          field="width"
          min-width="80"
          show-overflow-tooltip
          title="宽"
        />
        <vxe-column
          align="center"
          field="height"
          min-width="80"
          show-overflow-tooltip
          title="高"
        />
        <vxe-column
          align="center"
          field="transactionUrl"
          fixed="right"
          min-width="80"
          show-overflow-tooltip
          title="链接"
        >
          <template #default="{ row }">
            <a :href="row.transactionUrl" target="_blank">
              {{ row.transactionUrl }}
            </a>
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          field="customsType"
          min-width="80"
          title="报关方式"
        />
        <vxe-column
          align="center"
          field="taxType"
          min-width="80"
          title="清关方式"
        />
        <vxe-column
          align="center"
          field="createBy"
          min-width="100"
          title="创建人"
        />
        <vxe-column
          align="center"
          field="createTime"
          min-width="160"
          title="创建时间"
        />
        <vxe-column
          align="center"
          field="dataSourceName"
          min-width="120"
          title="数据来源"
        />
        <vxe-column
          align="center"
          field="statusName"
          min-width="80"
          title="状态"
        />
        <vxe-column
          align="center"
          field="isMixed"
          min-width="160"
          title="是否混装"
        />
        <vxe-column
          align="center"
          field="option"
          fixed="right"
          show-header-overflow
          title="操作"
          width="160"
        >
          <template #default="{ row }">
            <el-button
              :disabled="!row.pictureUrl"
              style="padding: 0px"
              type="text"
              @click="preview(row)"
            >
              图片预览
            </el-button>
            <el-button
              style="padding: 0px"
              type="text"
              @click="showSearchPanel(row)"
            >
              查看报关推荐
            </el-button>
          </template>
        </vxe-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </vxe-table>
    </div>

    <div class="cont">
      全部合计：
      <span>订单毛重：{{ fomr.totalGrossWeight | toFixedNum }}</span>
      <span>订单净重：{{ fomr.totalNetWeight | toFixedNum }}</span>
      <span>收货毛重：{{ fomr.totalReceiveGrossWeight | toFixedNum }}</span>
      <span>收货净重：{{ fomr.totalReceiveNetWeight | toFixedNum }}</span>
      <span>件数：{{ fomr.totalPcs }}</span>
      <span>总数量：{{ fomr.totalQty }}</span>
      <span>金额：{{ fomr.totalPrice | toFixedNum }}</span>
      <span>体积：{{ fomr.totalVol | toFixedNum }}</span>
    </div>
    <div class="cont">
      选中数据合计：
      <span>订单毛重：{{ selectForm.grossWeight }}</span>
      <span>订单净重：{{ selectForm.netWeight }}</span>
      <span>收货毛重：{{ selectForm.receiveGrossWeight }}</span>
      <span>收货净重：{{ selectForm.receiveNetWeight }}</span>
      <span>件数：{{ selectForm.pcs }}</span>
      <span>总数量：{{ selectForm.qtySum }}</span>
      <span>金额：{{ selectForm.priceSum }}</span>
      <span>体积：{{ selectForm.volSum }}</span>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :page-sizes="[100, 200, 300, 500, 1000]"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <Preview ref="preview" />
    <Sync ref="sync" />
    <search-panel
      :open.sync="showSearch"
      :search-info="searchInfo"
      @refresh="refreshTime"
      @save="queryList"
    />

    <update-hs-code-dialog
      :default-hs-code="defaultHsCode"
      :visible.sync="hsCodeDialogVisible"
      @confirm="handleUpdateHsCode"
    />
  </div>
</template>

<script>
  import VabQueryForm from '@/vab/components/VabQueryForm/index.vue'

  let color_type = ''
  let color_lastIdList = []

  import {
    addHighRiskCmsHighRiskData,
    addSpiderTaskCmsClearanceSpider,
    cancelClearanceList,
    clearanceListExportProfile,
    clearanceListMathHsData,
    confirmClearanceList,
    createFileClearanceList,
    deleteClearanceList,
    draftCopyExport,
    exportClearanceList,
    getOneCmsCount,
    importClearanceList,
    pageClearanceListTwo,
    updateClearanceList,
    updateDraftClearanceList,
    updateHsCodeApi,
  } from '@/api/cmsApi'

  import Preview from '@/vab/components/Preview'
  import VabQueryFormLeftPanel from '@/vab/components/VabQueryForm/components/VabQueryFormLeftPanel.vue'
  import VabQueryFormTopPanel from '@/vab/components/VabQueryForm/components/VabQueryFormTopPanel.vue'
  import throttle from 'lodash/throttle'
  import SearchPanel from './components/searchPanel.vue'
  import Sync from './components/sync'

  import tableConfigMixin from '@/mixins/tableConfigMixin'
  import UpdateHsCodeDialog from './components/updateHsCodeDialog.vue'

  export default {
    name: 'ClearanceList',
    components: {
      VabQueryFormLeftPanel,
      VabQueryFormTopPanel,
      VabQueryForm,
      Preview,
      Sync,
      SearchPanel,
      UpdateHsCodeDialog,
    },
    filters: {
      toFixedNum: function (value) {
        return value.toFixed(2)
      },
    },
    mixins: [tableConfigMixin],
    data() {
      return {
        statusList: [
          { id: '0', name: '全部' },
          { id: '1', name: '待确认' },
          { id: '2', name: '已确认' },
        ],
        customsType: ['自备', '买单'],
        taxType: ['自税', '包税'],
        yseOrNo: ['是', '否'],
        tableData: [],
        pageList: [],
        fold: false,
        loadingExPort: false,
        loadingExPort2: false,
        loadingHsData: false,
        listLoading: false,
        loadingLinking: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        height: this.$baseTableHeight(3) + 300,
        queryForm: {
          isCompletion: '',
          cabinetNo: '',
          isMixed: '',
          jobNo: '',
          childJobNo: '',
          customsType: '',
          taxType: '',
          cusProductCnName: '',
          materialCn: '',
          status: 0,
          pageNum: 1,
          pageSize: 500,
          idList: [],
        },
        //全部数据合计
        fomr: {
          totalNetWeight: 0,
          totalGrossWeight: 0,
          totalReceiveNetWeight: 0,
          totalReceiveGrossWeight: 0,
          totalPcs: 0,
          totalQty: 0,
          totalPrice: 0,
          totalVol: 0,
        },
        //选中数据合计
        selectForm: {
          receiveNetWeight: 0,
          receiveGrossWeight: 0,
          grossWeight: 0,
          netWeight: 0,
          pcs: 0,
          qtySum: 0,
          priceSum: 0,
          volSum: 0,
        },
        pickerOptions: {
          disabledDate(v) {
            return v.getTime() < new Date().getTime() - 86400000
          },
        },
        //查询面板
        showSearch: false,
        //查询信息
        searchInfo: [
          {
            id: '',
            reId: '',
          },
        ],
        useTimes: 0,
        // 添加新的数据属性
        hsCodeDialogVisible: false,
        defaultHsCode: '',
      }
    },
    mounted() {},
    created() {
      this.queryList()
    },
    methods: {
      batchView() {
        // 获取选中记录
        const selectedRecords = this.$refs.tableRef.getCheckboxRecords()

        // 校验是否选择数据
        if (!selectedRecords.length) {
          this.$message.warning('请先选择要操作的数据')
          return
        }

        // 获取第一个记录的品名和材质
        const firstProduct = selectedRecords[0].cusProductCnName
        const firstMaterial = selectedRecords[0].materialCn

        // 校验所有选中项
        const isSame = selectedRecords.every(
          (record) =>
            record.cusProductCnName === firstProduct &&
            record.materialCn === firstMaterial
        )

        if (!isSame) {
          this.$message.warning('请选择同客户中文品名、客户中文材质的数据！')
          return
        }

        this.showSearch = true
        this.searchInfo = selectedRecords
      },
      updateHsCode() {
        const selectedRecords = this.$refs.tableRef.getCheckboxRecords()

        if (!selectedRecords.length) {
          this.$message.warning('请先选择要操作的数据')
          return
        }

        this.hsCodeDialogVisible = true

        const firstHsCode = selectedRecords[0].hsCode
        if (selectedRecords.every((record) => record.hsCode === firstHsCode)) {
          this.defaultHsCode = firstHsCode
        }
      },
      async highRisk() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm(
          '你确定将勾选数据转高风险资料吗？',
          null,
          async () => {
            const res = await addHighRiskCmsHighRiskData(data)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            await this.queryList()
          }
        )
      },
      picturesLinking() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.loadingLinking = true
        this.$baseConfirm('您是否确认爬取图片链接?', null, async () => {
          try {
            const res = await addSpiderTaskCmsClearanceSpider(data)
            this.loadingLinking = false
            this.$baseMessage(
              '提交成功，可在任务管理查看进度！',
              'success',
              'vab-hey-message-success'
            )
          } catch (error) {
            this.loadingLinking = false
            console.log(error)
          } finally {
            await this.queryList()
          }
        })
      },
      showSearchPanel(searchInfo) {
        this.showSearch = true
        this.searchInfo = [searchInfo]
      },
      selectAllEvent({ records }) {
        this.queryForm.idList = records.map((item) => item.id)
        this.selectForm.grossWeight = this.countTotal(records, 'grossWeight')
        this.selectForm.netWeight = this.countTotal(records, 'netWeight')
        this.selectForm.pcs = this.countTotalTwo(records, 'pcs')
        this.selectForm.qtySum = this.countTotalTwo(records, 'qtySum')
        this.selectForm.priceSum = this.countTotal(records, 'priceSum')
        this.selectForm.volSum = this.countTotal(records, 'volSum')
        this.selectForm.receiveNetWeight = this.countTotal(
          records,
          'receiveNetWeight'
        )
        this.selectForm.receiveGrossWeight = this.countTotal(
          records,
          'receiveGrossWeight'
        )
      },
      //选中计算
      countTotal(arr, keyName) {
        let $total = 0
        $total = arr.reduce(function (total, currentValue) {
          return currentValue[keyName] ? total + currentValue[keyName] : total
        }, 0)
        return $total.toFixed(2)
      },
      countTotalTwo(arr, keyName) {
        let $total = 0
        $total = arr.reduce(function (total, currentValue) {
          return currentValue[keyName] ? total + currentValue[keyName] : total
        }, 0)
        return $total
      },
      tmsDataSyncOpen() {
        this.$refs['sync'].show()
      },
      //图片预览
      preview(row) {
        this.$refs['preview'].showEdit(row)
      },
      //重置
      reset(queryForm) {
        this.$refs[queryForm].resetFields()
        this.queryForm.pageNum = 1
        this.queryList()
      },
      handleSizeChange(val) {
        this.queryForm.idList = []
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.queryForm.idList = []
        this.queryForm.pageNum = val
        this.queryList()
      },
      // 处理导出逻辑
      async handleExport(exportFunction, loadingState) {
        try {
          if (!this.queryForm.idList.length) {
            this.$message.warning('请勾选导出数据')
            return
          }
          this[loadingState] = true
          const res = await exportFunction(this.queryForm)
          this[loadingState] = false
          this.jump(res.data)
        } catch (error) {
          this[loadingState] = false
          this.$message.error('导出失败!')
        }
      },
      bottomOrderExport() {
        this.handleExport(draftCopyExport, 'loadingExPort2')
      },
      exportOfCustomsDeclarationDocuments() {
        this.handleExport(clearanceListExportProfile, 'loadingExPort')
      },
      async mathHsData() {
        let data = {
          idList: this.queryForm.idList,
        }
        try {
          this.loadingHsData = true
          const res = await clearanceListMathHsData(data)
          this.loadingHsData = false
          this.jump(res.data)
        } catch (error) {
          this.loadingHsData = false
        }
      },
      deleteData() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm('你确定全部【删除】勾选数据吗？', null, async () => {
          const res = await deleteClearanceList(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.queryList()
        })
      },
      updateData() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm('你确定全部【更新】勾选数据吗？', null, async () => {
          const res = await updateClearanceList(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.queryList()
        })
      },
      createFile() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm(
          '你确定全部【创建档案】勾选数据吗？',
          null,
          async () => {
            const res = await createFileClearanceList(data)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            await this.queryList()
          }
        )
      },
      cancel() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm(
          '你确定全部【取消确认】勾选数据吗？',
          null,
          async () => {
            const res = await cancelClearanceList(data)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            await this.queryList()
          }
        )
      },
      confirm() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm('你确定全部【确认】勾选数据吗？', null, async () => {
          const res = await confirmClearanceList(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.queryList()
        })
      },
      rowClassName({ row }) {
        if (row.isHighRisk === 1) {
          return 'high-risk-row'
        }
        return ''
      },
      // 设置单元格样式
      cellClassName({ row, column }) {
        if (!row.hasGroup) {
          return null
        }
        if (column.fixed) {
          return null
        }

        let id = row.id
        if (!color_type) {
          color_type = 1
          color_lastIdList = row.idList
        }

        if (color_lastIdList.indexOf(id) > -1) {
          if (color_type === 1) {
            return 'cell-bg-1'
          } else {
            return 'cell-bg-2'
          }
        } else {
          color_lastIdList = row.idList
          if (color_type === 1) {
            color_type = 2
          } else {
            color_type = 1
          }
        }

        if (color_lastIdList.indexOf(id) > -1) {
          if (color_type === 1) {
            return 'cell-bg-1'
          } else {
            return 'cell-bg-2'
          }
        }
      },
      handleRowClick(row, column) {
        if (column && column.label == '操作') {
          return
        }
        this.$refs.tableRef.toggleRowSelection(row)
      },
      handleQuery() {
        this.queryForm.pageNum = 1
        this.queryList()
      },
      async throttleFn() {
        const result = await getOneCmsCount()
        this.useTimes = result?.data?.usageCounts

        this.queryForm.idList = []
        this.listLoading = true
        const res = await pageClearanceListTwo(this.queryForm)
        // 全部合计
        this.fomr.totalNetWeight = res.data.totalNetWeight
        this.fomr.totalGrossWeight = res.data.totalGrossWeight
        this.fomr.totalReceiveNetWeight = res.data.totalReceiveNetWeight
        this.fomr.totalReceiveGrossWeight = res.data.totalReceiveGrossWeight
        this.fomr.totalPcs = res.data.totalPcs
        this.fomr.totalQty = res.data.totalQty
        this.fomr.totalPrice = res.data.totalPrice
        this.fomr.totalVol = res.data.totalVol
        // 选中数据合计
        this.selectForm.receiveNetWeight = 0
        this.selectForm.receiveGrossWeight = 0
        this.selectForm.grossWeight = 0
        this.selectForm.netWeight = 0
        this.selectForm.pcs = 0
        this.selectForm.qtySum = 0
        this.selectForm.priceSum = 0
        this.selectForm.volSum = 0

        this.total = res.data.totalSize
        this.tableData = res.data.pageList
        this.listLoading = false
      },
      async refreshTime() {
        const result = await getOneCmsCount()
        this.useTimes = result?.data?.usageCounts
      },
      //查询
      queryList: throttle(function () {
        this.throttleFn()
      }, 1000),
      doLayout() {
        let that = this
        this.$nextTick(() => {
          that.$refs.tableRef.doLayout()
        })
      },
      handleFold() {
        this.fold = !this.fold
      },
      updateD() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm('你确定【更新底单】勾选数据吗？', null, async () => {
          const res = await updateDraftClearanceList(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.queryList()
        })
      },

      jump(data) {
        this.$router.push({
          name: 'taskManage',
          params: {
            taskNo: data,
          },
        })
      },
      async exPort() {
        try {
          this.loadingExPort = true
          const res = await exportClearanceList(this.queryForm)
          this.loadingExPort = false
          this.jump(res.data)
        } catch (error) {
          this.loadingExPort = false
          this.$message.error('导出失败!')
        }
      },
      async bt(form) {
        try {
          const res = await importClearanceList(form)
          this.jump(res.data)
          this.queryList()
        } catch (error) {
          this.queryList()
        }
      },
      // 添加确认修改方法
      async handleUpdateHsCode(hsCode) {
        try {
          // TODO: 调用修改海关编码的API
          await updateHsCodeApi({
            ids: this.queryForm.idList,
            hsCodeCn: hsCode,
          })

          this.$message.success('修改成功')
          this.hsCodeDialogVisible = false
          this.queryList() // 刷新列表
        } catch (error) {
          this.$message.error('修改失败')
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .comprehensive-table-container {
    position: relative;
  }
  .posit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
  .cont {
    margin-top: 20px;
    span {
      margin-right: 20px;
    }
  }

  ::v-deep {
    // .el-tabs__item{
    //   height: 30px !important;
    //   line-height: 30px !important;
    // }
    // .el-button--small{
    //   padding: 0px 15px !important;
    // }
    .vab-query-form[data-v-64063760] .top-panel {
      margin: 0px;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
    .left-panel {
      margin: 0px !important;
    }
    .el-table__body-wrapper.is-scrolling-right ~ .el-table__fixed-right {
      height: auto !important;
      bottom: 10px !important;
      &::before {
        background-color: transparent;
      }
    }
    .el-table__body-wrapper.is-scrolling-left ~ .el-table__fixed {
      height: auto !important;
      bottom: 10px !important;
      &::before {
        background-color: transparent;
      }
    }

    .rowStyle {
      background-color: #e8f7fd !important;
      color: #1890ff;
    }
  }
  // .my-form-item {
  // padding-left: 10px !important;
  // }
  .cont-form {
    display: flex;
  }

  .tools {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    margin-bottom: 10px;
  }

  .columns-list {
    display: flex;
    flex-wrap: wrap;
  }

  .columns-list .el-checkbox {
    flex: 0 0 calc(20% - 10px); /* 每个复选框占1/5宽，减去间距 */
    margin: 5px;
    text-align: left; /* 首字符对齐 */
  }

  .columns-list .first-in-row {
    margin-left: 0; /* 第一个元素没有左边距 */
  }

  /* 确保全选复选框和按钮在同一行 */
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dialog-footer .el-checkbox {
    margin-right: auto; /* 将全选复选框推到左侧 */
  }
  ::v-deep {
    // 添加高风险行的样式
    .high-risk-row {
      background-color: #f56c6c !important;
      color: #ffffff !important;

      // 确保下拉菜单等内容也使用白色
      .el-dropdown-link {
        color: #ffffff !important;
      }

      // 确保链接也使用白色
      a {
        color: #ffffff !important;
      }
    }
  }
</style>
