var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  attrs: {
                    inline: true,
                    "label-width": "140px",
                    model: _vm.queryForm
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "cont-form" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "",
                              "label-width": "60px",
                              prop: "jobNo"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "运单号",
                                rows: 3,
                                type: "textarea"
                              },
                              model: {
                                value: _vm.queryForm.jobNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "jobNo", $$v)
                                },
                                expression: "queryForm.jobNo"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "柜号", prop: "cabinetNo" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "180px" },
                              attrs: { clearable: "", placeholder: "请输入" },
                              model: {
                                value: _vm.queryForm.cabinetNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "cabinetNo", $$v)
                                },
                                expression: "queryForm.cabinetNo"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "子单号", prop: "childJobNo" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "180px" },
                              attrs: { clearable: "", placeholder: "请输入" },
                              model: {
                                value: _vm.queryForm.childJobNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "childJobNo", $$v)
                                },
                                expression: "queryForm.childJobNo"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "是否混装", prop: "isMixed" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.isMixed,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "isMixed", $$v)
                                  },
                                  expression: "queryForm.isMixed"
                                }
                              },
                              _vm._l(_vm.yseOrNo, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "报关方式", prop: "customsType" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.customsType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "customsType", $$v)
                                  },
                                  expression: "queryForm.customsType"
                                }
                              },
                              _vm._l(_vm.customsType, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "清关方式", prop: "taxType" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.taxType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "taxType", $$v)
                                  },
                                  expression: "queryForm.taxType"
                                }
                              },
                              _vm._l(_vm.taxType, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: {
                              label: "客户中文品名",
                              prop: "cusProductCnName"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "180px" },
                              attrs: { clearable: "", placeholder: "请输入" },
                              model: {
                                value: _vm.queryForm.cusProductCnName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.queryForm,
                                    "cusProductCnName",
                                    $$v
                                  )
                                },
                                expression: "queryForm.cusProductCnName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "中文材质", prop: "materialCn" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "180px" },
                              attrs: { clearable: "", placeholder: "请输入" },
                              model: {
                                value: _vm.queryForm.materialCn,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "materialCn", $$v)
                                },
                                expression: "queryForm.materialCn"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { "native-type": "submit" },
                          on: {
                            click: function($event) {
                              return _vm.reset("queryForm")
                            }
                          }
                        },
                        [_vm._v(" 重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("vab-query-form-left-panel", [
            _c("div", { staticClass: "tools" }, [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.queryForm.status == 1,
                          expression: "queryForm.status == 1"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.queryForm.idList.length > 0,
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.confirm }
                    },
                    [_vm._v(" 确认 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.queryForm.status == 2,
                          expression: "queryForm.status == 2"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.queryForm.idList.length > 0,
                        size: "mini",
                        type: "danger"
                      },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(" 取消确认 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.queryForm.status == 1,
                          expression: "queryForm.status == 1"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.queryForm.idList.length > 0,
                        size: "mini",
                        type: "danger"
                      },
                      on: { click: _vm.deleteData }
                    },
                    [_vm._v(" 删除 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.queryForm.status == 0 ||
                            _vm.queryForm.status == 1,
                          expression:
                            "queryForm.status == 0 || queryForm.status == 1"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.queryForm.idList.length > 0,
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.createFile }
                    },
                    [_vm._v(" 创建档案 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.queryForm.status == 2,
                          expression: "queryForm.status == 2"
                        }
                      ],
                      attrs: {
                        loading: _vm.loadingExPort,
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.exPort }
                    },
                    [_vm._v(" 导出 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.queryForm.status == 2,
                          expression: "queryForm.status == 2"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.queryForm.idList.length > 0,
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.updateD }
                    },
                    [_vm._v(" 更新底单 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.tmsDataSyncOpen }
                    },
                    [_vm._v(" 手动运单同步 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.queryForm.status == 0,
                          expression: "queryForm.status == 0"
                        }
                      ],
                      attrs: {
                        loading: _vm.loadingExPort2,
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.bottomOrderExport }
                    },
                    [_vm._v(" 底单导出 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.queryForm.status == 0,
                          expression: "queryForm.status == 0"
                        }
                      ],
                      attrs: {
                        loading: _vm.loadingExPort,
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.exportOfCustomsDeclarationDocuments }
                    },
                    [_vm._v(" 报关资料导出 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.queryForm.status == 0 ||
                            _vm.queryForm.status == 1,
                          expression:
                            "queryForm.status == 0 || queryForm.status == 1"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.queryForm.idList.length > 0,
                        loading: _vm.loadingHsData,
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.mathHsData }
                    },
                    [_vm._v(" 查询海关编码网站 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: !_vm.queryForm.idList.length > 0,
                        loading: _vm.loadingHsData,
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.batchView }
                    },
                    [_vm._v(" 批量查看报关推荐 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.queryForm.status == 0 ||
                            _vm.queryForm.status == 1,
                          expression:
                            "queryForm.status == 0 || queryForm.status == 1"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.queryForm.idList.length > 0,
                        loading: _vm.loadingHsData,
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.updateHsCode }
                    },
                    [_vm._v(" 修改中国海关编码 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: !_vm.queryForm.idList.length > 0,
                        size: "mini",
                        type: "danger"
                      },
                      on: { click: _vm.highRisk }
                    },
                    [_vm._v(" 转高风险资料 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: !_vm.queryForm.idList.length > 0,
                        loading: _vm.loadingLinking,
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.picturesLinking }
                    },
                    [_vm._v(" 获取图片链接 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.openColumnSettings }
                    },
                    [_vm._v(" 设置列 ")]
                  )
                ],
                1
              ),
              _c("div", [
                _vm._v("网站接口调用次数： " + _vm._s(_vm.useTimes) + "/5500")
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticStyle: { width: "100%" },
          attrs: { type: "card" },
          on: { "tab-click": _vm.queryList },
          model: {
            value: _vm.queryForm.status,
            callback: function($$v) {
              _vm.$set(_vm.queryForm, "status", $$v)
            },
            expression: "queryForm.status"
          }
        },
        _vm._l(_vm.statusList, function(item) {
          return _c("el-tab-pane", {
            key: item.id,
            staticStyle: { height: "25px", "line-height": "25px" },
            attrs: { label: item.name, name: item.id }
          })
        }),
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 470px)" } },
        [
          _c(
            "vxe-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableRef",
              attrs: {
                id: "clearanceListTable",
                border: "",
                "checkbox-config": { trigger: "row", range: true },
                "custom-config": _vm.customConfig,
                data: _vm.tableData,
                height: "100%",
                "row-class-name": _vm.rowClassName,
                "row-config": { isHover: true, isCurrent: true, height: 35 },
                "scroll-y": { enabled: true },
                "show-header-overflow": "",
                "show-overflow": ""
              },
              on: {
                "checkbox-all": _vm.selectAllEvent,
                "checkbox-change": _vm.selectAllEvent
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png")
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "selectOption",
                  fixed: "left",
                  type: "checkbox",
                  width: "55"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  fixed: "left",
                  title: "序号",
                  type: "seq",
                  width: "70"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var seq = ref.seq
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              seq +
                                (_vm.queryForm.pageNum - 1) *
                                  _vm.queryForm.pageSize
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "cabinetNo",
                  "min-width": "140",
                  title: "柜号"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "jobNo",
                  "min-width": "140",
                  title: "运单号"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "customerCode",
                  "min-width": "140",
                  title: "客户"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "cusProductCnName",
                  "min-width": "140",
                  title: "客户中文品名"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "cusProductEnName",
                  "min-width": "140",
                  title: "客户英文品名"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "hsCode",
                  "min-width": "120",
                  title: "海关编码"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "reportCnName",
                  "min-width": "140",
                  title: "申报中文品名"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "reportEnName",
                  "min-width": "140",
                  title: "申报英文品名"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "hsCodeCn",
                  "min-width": "120",
                  title: "中国海关编码"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "reportFactor",
                  "min-width": "120",
                  title: "中国申报要素"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "materialCn",
                  "min-width": "140",
                  title: "中文材质"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "materialEn",
                  "min-width": "140",
                  title: "英文材质"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "childJobNo",
                  "min-width": "240",
                  "show-overflow-tooltip": "",
                  title: "子单号"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "brand",
                  "min-width": "140",
                  title: "品牌"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "brandType",
                  "min-width": "140",
                  title: "品牌类型"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "specs",
                  "min-width": "140",
                  title: "规格"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "uses",
                  "min-width": "140",
                  title: "用途"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "goodsType",
                  "min-width": "120",
                  title: "货物类型"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "unit",
                  "min-width": "80",
                  title: "单位"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "pcs",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "件数"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "splitPcs",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "拆分件数"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "netWeight",
                  "min-width": "100",
                  title: "净重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "splitNetWeight",
                  "min-width": "100",
                  title: "拆分净重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "receiveNetWeight",
                  "min-width": "100",
                  title: "收货总净重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "splitReceiveNetWeight",
                  "min-width": "120",
                  title: "拆分收货总净重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "qty",
                  "min-width": "80",
                  title: "数量"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "vol",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "体积"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "splitVol",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                  title: "拆分体积"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "grossWeight",
                  "min-width": "80",
                  title: "毛重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "splitGrossWeight",
                  "min-width": "100",
                  title: "拆分毛重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "receiveGrossWeight",
                  "min-width": "100",
                  title: "收货总实重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "spliTreceiveGrossWeight",
                  "min-width": "120",
                  title: "拆分收货总实重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "price",
                  "min-width": "80",
                  title: "单价"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "awsPrice",
                  "min-width": "100",
                  title: "亚马逊单价"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "totalPrice",
                  "min-width": "80",
                  title: "总价"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "length",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "长"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "width",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "宽"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "height",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "高"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "transactionUrl",
                  fixed: "right",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "链接"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: row.transactionUrl,
                              target: "_blank"
                            }
                          },
                          [_vm._v(" " + _vm._s(row.transactionUrl) + " ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "customsType",
                  "min-width": "80",
                  title: "报关方式"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "taxType",
                  "min-width": "80",
                  title: "清关方式"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "createBy",
                  "min-width": "100",
                  title: "创建人"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "createTime",
                  "min-width": "160",
                  title: "创建时间"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "dataSourceName",
                  "min-width": "120",
                  title: "数据来源"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "statusName",
                  "min-width": "80",
                  title: "状态"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "isMixed",
                  "min-width": "160",
                  title: "是否混装"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "option",
                  fixed: "right",
                  "show-header-overflow": "",
                  title: "操作",
                  width: "160"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0px" },
                            attrs: { disabled: !row.pictureUrl, type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.preview(row)
                              }
                            }
                          },
                          [_vm._v(" 图片预览 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0px" },
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.showSearchPanel(row)
                              }
                            }
                          },
                          [_vm._v(" 查看报关推荐 ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "cont" }, [
        _vm._v(" 全部合计： "),
        _c("span", [
          _vm._v(
            "订单毛重：" +
              _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalGrossWeight))
          )
        ]),
        _c("span", [
          _vm._v(
            "订单净重：" + _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalNetWeight))
          )
        ]),
        _c("span", [
          _vm._v(
            "收货毛重：" +
              _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalReceiveGrossWeight))
          )
        ]),
        _c("span", [
          _vm._v(
            "收货净重：" +
              _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalReceiveNetWeight))
          )
        ]),
        _c("span", [_vm._v("件数：" + _vm._s(_vm.fomr.totalPcs))]),
        _c("span", [_vm._v("总数量：" + _vm._s(_vm.fomr.totalQty))]),
        _c("span", [
          _vm._v("金额：" + _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalPrice)))
        ]),
        _c("span", [
          _vm._v("体积：" + _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalVol)))
        ])
      ]),
      _c("div", { staticClass: "cont" }, [
        _vm._v(" 选中数据合计： "),
        _c("span", [_vm._v("订单毛重：" + _vm._s(_vm.selectForm.grossWeight))]),
        _c("span", [_vm._v("订单净重：" + _vm._s(_vm.selectForm.netWeight))]),
        _c("span", [
          _vm._v("收货毛重：" + _vm._s(_vm.selectForm.receiveGrossWeight))
        ]),
        _c("span", [
          _vm._v("收货净重：" + _vm._s(_vm.selectForm.receiveNetWeight))
        ]),
        _c("span", [_vm._v("件数：" + _vm._s(_vm.selectForm.pcs))]),
        _c("span", [_vm._v("总数量：" + _vm._s(_vm.selectForm.qtySum))]),
        _c("span", [_vm._v("金额：" + _vm._s(_vm.selectForm.priceSum))]),
        _c("span", [_vm._v("体积：" + _vm._s(_vm.selectForm.volSum))])
      ]),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNum,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [100, 200, 300, 500, 1000],
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c("Preview", { ref: "preview" }),
      _c("Sync", { ref: "sync" }),
      _c("search-panel", {
        attrs: { open: _vm.showSearch, "search-info": _vm.searchInfo },
        on: {
          "update:open": function($event) {
            _vm.showSearch = $event
          },
          refresh: _vm.refreshTime,
          save: _vm.queryList
        }
      }),
      _c("update-hs-code-dialog", {
        attrs: {
          "default-hs-code": _vm.defaultHsCode,
          visible: _vm.hsCodeDialogVisible
        },
        on: {
          "update:visible": function($event) {
            _vm.hsCodeDialogVisible = $event
          },
          confirm: _vm.handleUpdateHsCode
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }